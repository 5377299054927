import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Footer, Nav, Seo } from "../components";
import "react-multi-carousel/lib/styles.css";

const gallery = ({ data }) => {
	const options = {
		buttons: {
			backgroundColor: "rgba(30,30,36,0.8)",
			iconColor: "rgba(255, 255, 255, 0.8)",
			iconPadding: "10px",
			showAutoplayButton: true,
			showCloseButton: true,
			showDownloadButton: false,
			showFullscreenButton: true,
			showNextButton: true,
			showPrevButton: true,
			showThumbnailsButton: true,
			size: "40px",
		},
	};
	return (
		<div>
			<Seo
				title='Gallery: Car and Auto Window Tinting Near Me'
				description='Check out the services of the Car Window Tinting Near me, and you will see the fine work of the Ottawa tinting shop at a reasonable price under the supervision of experts.'
				keywords={[
					"Car Window Tinting Near me",
					"Auto Window Tinting Near me",
					"Auto Tinting Near me",
				]}
			/>
			<div id='root' className='flex flex-col'>
				<Nav />
				<div className='mx-auto mt-4 pb-16'>
					<h1 className=' ml-4 text-3xl text-center'>
						A Showcase of Ottawa Car Tinting Work
					</h1>
					<p className='ml-4 mt-4 pb-4 px-2'>
						Don't forget to check the final result of the Auto
						Window Tinting near me and choose the best company that
						gives you a pleasing result. No one likes to settle with
						incomplete or lazy work, and Ottawa Tinting company
						believes in completing every work with a dedication that
						is visible in the gallery section of Car Window Tinting.
					</p>
					<SimpleReactLightbox>
						<SRLWrapper options={options}>
							<div className='flex flex-row flex-wrap gap-5 justify-center'>
								{data.gallery.edges.map(({ node }) => (
									<div key={node.id}>
										<a href={node.publicURL}>
											<GatsbyImage
												image={
													node.childImageSharp
														.gatsbyImageData
												}
												alt={
													node.base
														.split("-")
														.join(" ")
														.split(".")[0]
												}
											/>
										</a>
									</div>
								))}
							</div>
						</SRLWrapper>
					</SimpleReactLightbox>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default gallery;

export const galleryQuery = graphql`
	query {
		gallery: allFile(
			filter: { relativeDirectory: { eq: "gallery" } }
			sort: { fields: base, order: ASC }
		) {
			edges {
				node {
					id
					base
					publicURL
					childImageSharp {
						gatsbyImageData(
							height: 400
							width: 600
							transformOptions: { fit: COVER }
							placeholder: BLURRED
							webpOptions: { quality: 50 }
						)
					}
				}
			}
		}
	}
`;
